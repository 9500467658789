@import (inline) "npm://purecss/build/pure.css";
@import (inline) "npm://purecss/build/grids-responsive.css";

*, *:before, *:after {
  box-sizing: border-box;
}

body {
  background-color: #eceff1;
}

html, button, input, select, textarea, .pure-g [class *= "pure-u"] {
  font-family: 'Source Sans Pro',sans-serif;
  color: #263238;
}

h1 {
  font-size: 2em;
}

a {
  text-decoration: none;
  cursor: pointer;
}

img {
  border: 0;
}

.header {
  background-color: #76a6ad;
  box-shadow: 0 1px 6px rgba(0,0,0,.12);
  width: 100%;
  font-weight: 300;
  color: #fff;
  padding: 12px 16px 7px;

  div {
    display: inline-block;
  }

  h1, h3 {
    margin: 0;
    font-weight: 300;
  }
}

.name {
  margin-bottom: 4px;
}

.description {
  margin-left: 8px;
  color: rgba(255,255,255,.75);
}

.card.company-card {
  height: 160px;
  margin-bottom: 0;
}

.card.technology-card {
  height: 40px;
  min-width: 150px;
  display: inline-block;
  color: #263238;
  margin: 8px
}

.card {
  background-color: #fff;
  box-shadow: 0 1px 6px rgba(0,0,0,.12);
  max-width: 100%;
  margin: 16px;
}

.card-link {
  color: #263238;
  transition: box-shadow 200ms ease-in;
}

.card-link:hover {
  box-shadow: 0 10px 30px rgba(0,0,0,.19);
}

.company-card__image {
  float: left;
  width: 160px;
  height: 160px;
}

.company-card__image img {
  width: 160px;
  height: 160px;
}

.company-card__info {
  margin-left: 160px;
  text-align: right;
  font-weight: 300;
  height: 160px;
  padding: 16px;
}

.company-card__info > h2 {
  margin: 0;
  font-weight: 300;
}

.detail-card {
  padding: 24px;
}

.detail-card ul {
  margin: 0;
  padding: 0;
}

.detail-card li {
  list-style: none;
  margin: 8px 0;
}

.detail-card li:not(:first-child):before {
  display: block;
  content: " ";
  margin: 4px auto;
  width: 90%;
  background-color: rgba(38,50,56,.12);
  height: 1px;
}

.experience-header {
  margin: 16px 0 0 16px;
  font-weight: 300;
}

.technology-card-list {
  margin: 16px;
}

.technology-card__image {
  float: left;
  width: 40px;
  height: 40px
}

.technology-card__info {
  position: relative;
  margin-left: 40px;
  height: 40px;
  padding: 8px
}

.technology-card__info > h3 {
  margin: 0;
  font-weight: 300;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%)translateX(-50%);
}
